import React from 'react';
import Dice from 'react-dice-roll';

import './SicboDice.scss';

interface Props {
  value: number;
  rollingTime: number;
  sound?: string;
}

export const SicboDice = (props: Props) => {
  const small = window.innerWidth / window.innerHeight > 1.5 && window.innerHeight <= 600;
  return (
    <Dice
      rollingTime={props.rollingTime}
      size={small ? 40 : 60}
      cheatValue={props.value}
      sound={props.sound}
    />
  );
};
