export enum StepId {
  LATEST_RESULT = 'latest_result',
  GAME = 'game',
  TOP_BETS = 'top_bets',
  RESULT = 'result',
  HIGHLIGHT_BETS = 'highlight_bets',
  TOP_WINNERS = 'top_winners',
  HIGHLIGHT_WINNER_BETS = 'highlight_winner_bets',
  LOADING = 'loading',
  ERROR = 'error',
  OFFLINE = 'offline'
}

export interface GameStep {
  id: StepId,
  name: string,
  nextStep: StepId,
  time: number,
  message: string
}

export const gameSteps: GameStep[] = [
  {
    id: StepId.LATEST_RESULT,
    name: 'latest_result',
    nextStep: StepId.TOP_BETS,
    time: 0,
    message: 'gameState.latestResult',
  },
  {
    id: StepId.GAME,
    name: 'start_game',
    nextStep: StepId.TOP_BETS,
    time: 0,
    message: 'gameState.placeBet',
  },
  {
    id: StepId.TOP_BETS,
    name: 'top_bets',
    nextStep: StepId.RESULT,
    time: 5,
    message: 'gameState.topBets',
  },
  {
    id: StepId.RESULT,
    name: 'start_baccarat_game',
    nextStep: StepId.HIGHLIGHT_BETS,
    time: 0,
    message: 'gameState.result..',
  },
  {
    id: StepId.HIGHLIGHT_BETS,
    name: 'highlight_bets',
    // nextStep: StepId.TOP_WINNERS,
    nextStep: StepId.HIGHLIGHT_WINNER_BETS,
    time: 2,
    message: ''
  },
  {
    id: StepId.TOP_WINNERS,
    name: 'winners_list',
    nextStep: StepId.HIGHLIGHT_WINNER_BETS,
    time: 3,
    message: ''
  },
  {
    id: StepId.HIGHLIGHT_WINNER_BETS,
    name: 'highlight_wining',
    nextStep: StepId.GAME,
    time: 5, // Should be 2 when Top winners step is active
    message: '',
  },
  {
    id: StepId.LOADING,
    name: 'loading_page',
    nextStep: StepId.GAME,
    time: 5,
    message: 'gameState.gameClosed',
  },
  {
    id: StepId.ERROR,
    name: 'error_page',
    nextStep: StepId.GAME,
    time: 5,
    message: ''
  },
  {
    id: StepId.OFFLINE,
    name: 'offline_page',
    nextStep: StepId.GAME,
    time: 5,
    message: ''
  },
];
