import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserWithBalance } from '../../../Store';
import { roundBalance } from '../../../Utils';

interface Props {
  actions: any;
}

export const UserList = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [users, setUsers] = useState<UserWithBalance[]>([]);
  const [timeOut, setTimeOut] = useState(null);
  const [search, setSearch] = useState({
    search: '',
  });
  const labels = [
    { id: 'name', label: t('user.name') },
    { id: 'label', label: t('user.label') },
    { id: 'balance', label: t('user.balance') }
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    props.actions.userAction.getUsers(search.search, true).then((_users: UserWithBalance[]) => {
      setUsers(_users);
    }).catch(() => {
      setUsers([]);
    });
  };

  const handleChange = (event) => {
    clearTimeout(timeOut);
    const field = event.target.name;
    const commonData = { ...search };
    commonData[field] = event.target.value;
    return setSearch(commonData);
  };

  const handleKeyDown = (e) => {
    if(e.keyCode == 13) {
      fetchUsers();
    }
  }

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <div className='agent_body full_width'>
      <div className='search_box'>
        <TextField
          variant='outlined'
          size='small'
          type='text'
          name='search'
          className='search_box_inner'
          placeholder={t('label.search')}
          value={search.search}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' onClick={() => fetchUsers()}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className='full_table button_rows' id='user_table'>
        <div className='table_header'>
          {
            labels.map((label, i) => (
              <span className={'table_header_text'} key={'header.' + i}>{label.label}</span>
            ))
          }
        </div>
        <div className='table_body_main'>
          { search.search && users.length === 0 && (
            <div className='info_message'>{t('users.notFound', {search: search.search})}</div>
          )}
          {[...users].sort((a, b) => b.user?.created.localeCompare(a.user?.created)).map((singleUser, index) => (
            <div className='table_body_inner' key={'user.'+index}>
              <div className='table_body'>
                <div
                  className='table_body_list'
                  onClick={() => redirect(`${singleUser.user.id}`)}
                >
                  <span className='body_list'>{singleUser.user.name}</span>
                  <span className='body_list'>
                    {singleUser.user.labels.length > 0
                      ? singleUser.user.labels[0]
                      : ''}
                  </span>
                  <span className='body_list'>{roundBalance(singleUser.balance)}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
