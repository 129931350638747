import React from 'react';

import './GameOverlay.scss';
import TopBets from '../dialogs/TopBets';
import { SessionError } from '../dialogs/SessionError';
import { ConnectionProblem } from '../dialogs/ConnectionProblem';
import { GameClosedLoader } from '../GameClosedLoader';
import { StepId } from '../../Constants/gameSteps';
import { TrialResult } from '../dialogs/TrialResult';

interface Props {
  stepId: any;
  minutes: number;
  sessionError: boolean;
  isOnline: boolean;
  reload: () => void;
  website?: boolean;
  trial?: boolean;
  trialResult: number;
  trialStart: () => void;
}

export const GameOverlay = (props: Props) => {
  return (
    <>
      {props.stepId !== StepId.GAME && !props.website && (<div className='no_more_bet'></div>)}
      {props.stepId === StepId.TOP_BETS && !props.website && (<TopBets />)}
      {/*{!props.trial && props.stepId === StepId.TOP_WINNERS && !props.website && (<TopPayout />)}*/}
      {props.stepId === StepId.ERROR && props.sessionError && (<SessionError callback={props.reload} />)}
      {props.stepId === StepId.OFFLINE && !props.isOnline && (<ConnectionProblem callback={props.reload} />)}
      {props.stepId === StepId.LOADING && !props.website && <GameClosedLoader minutes={props.minutes} />}
      {props.trial && !props.website && typeof props.trialResult === 'number' && <TrialResult trialResult={props.trialResult} startPlay={props.trialStart}></TrialResult>}
    </>
  );
};
