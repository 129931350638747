import { cloneDeep } from 'lodash';
import { BACCARAT, ROULETTE, SICBO } from '../Constants';
import { getAccessToken, getDefaultChips, getUserDetail } from '../Utils';
import { AppState } from './app.state';
import { StepId } from '../Constants/gameSteps';

const accessToken = getAccessToken();

let token = null;
let userDetail = null;
if (accessToken !== 'undefined' && accessToken !== null) {
  token = accessToken;
  userDetail = getUserDetail();
}

const initialData: AppState = {
  routing: {},
  gameReducer: {
    accountId: null,
    applyChips: 0,
    baccarat: cloneDeep(BACCARAT),
    baccaratAppliedChips: 0,
    baccaratCardLength: 0,
    baccaratResult: null,
    baccaratGameResult: null,
    balance: null,
    bankerTotal: [],
    betHistory: [],
    betHistoryHasMore: true,
    confirmBet: false,
    creditTransactions: [],
    creditTransactionsHasMore: true,
    disableBet: false,
    error_msg: null,
    gameResult: null,
    isBaccaratChipsActive: false,
    isChipsActive: false,
    isError: false,
    isSicboChipsActive: false,
    lastSession: null,
    loading: false,
    message: 'Place Your Bet',
    messages: null,
    pastResults: [],
    playerTotal: [],
    roulette: cloneDeep(ROULETTE),
    rouletteAppliedChips: 0,
    rouletteGameResult: null,
    rouletteResult: null,
    selectedChip: getDefaultChips().find(c => c.id === localStorage.getItem('lastChip'))?.id || null,
    session: null,
    showWinningArea: false,
    sicbo: cloneDeep(SICBO),
    sicboAppliedChips: 0,
    sicboResult: null,
    sicboGameResult: null,
    statistics: {
      baccarat: null,
      roulette: null,
      sicbo: null,
    },
    stepId: StepId.GAME,
    stepTime: null,
    success_msg: null,
    token: token,
    topBets: [],
    transactionMessage: null,
    trial: false,
    userDetail: userDetail,
    users: [],
    winningChips: 0,
    baccaratWinningChips: 0,
    rouletteWinningChips: 0,
    sicboWinningChips: 0,
    operator: undefined
  },
};

export default initialData;
